<template>
  <Modal :value="visible"
         :title="dataId ? '修改' + title : '新增' + title"
         @on-visible-change="onChangeVisible"
         width="600">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>姓名：</p>
          <MyTreeSelect :value="name"
                        placeholder="姓名"
                        :data="personTreeArr"
                        search
                        @onSelectChange="onChangeName">
          </MyTreeSelect>
        </li>
        <!-- <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>所属机构：</p>
          <MyTreeSelect :value="departmentName"
                        placeholder="所属机构"
                        :data="departmentArr"
                        search
                        style="width: 100%"
                        @onSelectChange="onChangeDepartment">
          </MyTreeSelect>
        </li> -->
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>实发工资：</p>
          <Input v-model="realityWage"
                 maxlength="30"
                 v-floatLimit
                 placeholder="实发工资"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">月份：</p>
          <DatePicker type="month"
                      :value="month"
                      placeholder="选择月份"
                      @on-change="onChangeMonth"></DatePicker>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">考勤天数：</p>
          <Input v-model="checkDays"
                 maxlength="30"
                 v-floatLimit
                 clearable
                 placeholder="考勤天数"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>发放日期：</p>
          <DatePicker type="date"
                      :value="issueDate"
                      placeholder="发放日期"
                      @on-change="onChangeDate"></DatePicker>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">在岗天数：</p>
          <Input v-model="onDays"
                 maxlength="30"
                 v-floatLimit
                 clearable
                 placeholder="在岗天数"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">扣款天数：</p>
          <Input v-model="deductionsDays"
                 maxlength="30"
                 v-floatLimit
                 clearable
                 placeholder="扣款天数"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">基础工资：</p>
          <Input v-model="basicWage"
                 maxlength="30"
                 v-floatLimit
                 clearable
                 placeholder="基础工资"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">岗位工资：</p>
          <Input v-model="postWage"
                 maxlength="30"
                 v-floatLimit
                 clearable
                 placeholder="岗位工资"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">绩效考核：</p>
          <Input v-model="performance"
                 maxlength="30"
                 v-floatLimit
                 placeholder="绩效考核"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">补贴：</p>
          <Input v-model="subsidy"
                 maxlength="30"
                 v-floatLimit
                 placeholder="补贴"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">社保：</p>
          <Input v-model="socialSecurity"
                 maxlength="30"
                 v-floatLimit
                 placeholder="社保"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">公积金：</p>
          <Input v-model="reservedFunds"
                 maxlength="30"
                 v-floatLimit
                 placeholder="公积金"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">个税：</p>
          <Input v-model="personalIncomeTax"
                 maxlength="30"
                 v-floatLimit
                 placeholder="个税"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">扣款：</p>
          <Input v-model="withhold"
                 maxlength="30"
                 v-floatLimit
                 placeholder="扣款"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">其他：</p>
          <Input v-model="other"
                 maxlength="30"
                 v-floatLimit
                 clearable
                 placeholder="其他"></Input>
        </li>
        <li class="form-ul-li">
          <p class="li-title">附件：</p>
          <ul class="file-img">
            <UploadFile @uploadSuccess="uploadPicture"
                        :disabled="picture"
                        accept=".doc,image/*">
              <li>
                <el-image :src="picture"
                          fit="cover"
                          :preview-src-list="[picture]"
                          class="w-full h-full"
                          v-show="picture && getFileType(picture) == 1">
                </el-image>
                <img src="../../../../../assets/common/word.png"
                     v-show="picture && getFileType(picture) == 2"
                     @click.stop="onClickDownFile(picture)">
                <Icon type="md-close-circle"
                      size="24"
                      color="#fa5357"
                      class="file-img-deleteIcon"
                      @click.stop="onClickDeletePicture"
                      v-show="picture" />
                <Icon custom="i-icon icon-tianjia1"
                      size="40"
                      color="#B5B5B5"
                      v-show="!picture" />
              </li>
            </UploadFile>
          </ul>
        </li>
        <li class="form-ul-li">
          <p class="li-title"></p>
          <div class="file-tip">
            支持格式为png、jpg、jpeg、doc，最大不能超过20M；
          </div>
        </li>
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
import UploadFile from '@/components/common/UploadFile';
import MyTreeSelect from '../../../../common/MyTreeSelect.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: { UploadFile, MyTreeSelect },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    title: {
      type: String,
      default: '工资组成'
    },
    visible: Boolean,
    dataId: String
  },
  data () {
    return {
      loading: false,
      name: '',
      nameId: '',
      departmentName: '',
      departmentId: '',
      realityWage: '',
      month: '',
      checkDays: '',
      issueDate: '',
      onDays: '',
      deductionsDays: '',
      basicWage: '',
      postWage: '',
      performance: '',
      subsidy: '',
      socialSecurity: '',
      reservedFunds: '',
      personalIncomeTax: '',
      withhold: '',
      other: '',
      picture: '',
      remark: ''
    }
  },
  watch: {
    dataId (newVal) {
      newVal && this.getDetail()
    }
  },
  computed: {
    ...mapGetters({
      'departmentArr': 'getDepartmentTreeList',
      'personTreeArr': 'getPersonTreeList',
    })
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions([
      'updateDepartmentTreeList',
      'updatePersonTreeList'
    ]),
    init () {
      this.updateDepartmentTreeList()
      this.updatePersonTreeList()
    },
    getDetail () {
      this.$store.dispatch('getPersonCostWageDetail', this.dataId).then(res => {
        for (let i in res) {
          this[i] = res[i]
        }
      })
    },
    getFileType (file) {
      let filePath = file
      let index = filePath.lastIndexOf(".");
      let type = filePath.substr(index + 1);
      let imgArr = ['tif', 'pjp', 'webp', 'xbm', 'jxl', 'svgz', 'jpg', 'jpeg', 'ico', 'tiff', 'gif', 'svg', 'bmp', 'png', 'jfif', 'pjpeg', 'avif']
      if (imgArr.includes(type)) {
        return 1
      } else {
        return 2
      }
    },
    // 点击下载文件
    onClickDownFile (file) {
      window.open(file)
    },
    // 修改姓名
    onChangeName (section) {
      if (section.type === 'user') {
        this.nameId = section.id
        this.name = section.name
      }
    },
    // 修改所属机构
    onChangeDepartment (section) {
      this.departmentId = section.id
      this.departmentName = section.name
    },
    onChangeMonth (month) {
      this.month = month
    },
    onChangeDate (date) {
      this.issueDate = date
    },
    // 上传照片
    uploadPicture (arr) {
      if (arr.length > 0) {
        this.picture = arr[0].url
      }
    },
    // 删除图片
    onClickDeletePicture () {
      this.picture = ''
    },
    // 确定按钮
    onClickConfirm () {
      if (!this.nameId) return this.$Message.info('请选择姓名');
      // if (!this.departmentId) return this.$Message.info('请选择所属机构');
      if (!this.realityWage) return this.$Message.info('请输入实发工资');
      if (!this.issueDate) return this.$Message.info('请选择发放日期');
      let params = {
        nameId: this.nameId,
        // departmentId: this.departmentId,
        month: this.month,
        realityWage: this.realityWage,
        issueDate: this.issueDate,
        checkDays: this.checkDays,
        onDays: this.onDays,
        deductionsDays: this.deductionsDays,
        picture: this.picture,
        remark: this.remark,
        basicWage: this.basicWage,
        postWage: this.postWage,
        performance: this.performance,
        subsidy: this.subsidy,
        socialSecurity: this.socialSecurity,
        reservedFunds: this.reservedFunds,
        personalIncomeTax: this.personalIncomeTax,
        withhold: this.withhold,
        other: this.other,
      }
      if (this.dataId) params.id = this.dataId
      this.loading = true
      this.$store.dispatch('createOrEditPersonCostWage', params).then(res => {
        this.$emit('onClickConfirm', params)
        this.$emit('onChange', false)
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
        this.resetData()
      }
    },
    resetData () {
      this.name = ''
      this.nameId = ''
      this.departmentName = ''
      this.departmentId = ''
      this.realityWage = ''
      this.month = ''
      this.checkDays = ''
      this.issueDate = ''
      this.onDays = ''
      this.deductionsDays = ''
      this.basicWage = ''
      this.postWage = ''
      this.performance = ''
      this.subsidy = ''
      this.socialSecurity = ''
      this.reservedFunds = ''
      this.personalIncomeTax = ''
      this.withhold = ''
      this.other = ''
      this.picture = ''
      this.remark = ''
    }
  }
}
</script>

<style lang="scss" scoped>
</style>