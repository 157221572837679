<template>
  <div class="main">
    <div class="topbar">
      <Tabs v-model="currentTabIndex"
            class="tabs">
        <TabPane v-for="item in tabArr"
                 :key="item.name"
                 :label="item.name"></TabPane>
      </Tabs>
      <div class="topbar-search">
        <div class="flex a-center j-between">
          <div class="flex a-center">
            <Input v-model="name"
                   placeholder="姓名"
                   maxlength="30"
                   clearable
                   v-stringLimit
                   class="m-r-10"
                   style="width:160px" />
            <Input v-model="idNumber"
                   placeholder="身份证号"
                   maxlength="30"
                   clearable
                   v-stringLimit
                   class="m-r-10"
                   style="width:160px" />
            <Input v-model="workNumber"
                   placeholder="工号"
                   maxlength="30"
                   clearable
                   v-intLimit
                   class="m-r-10"
                   style="width:160px" />
            <template v-if="currentTabIndex===1">
              <Select v-model="costType"
                      placeholder="费用类型"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in costTypeArr"
                        :value="item.id"
                        :key="item.id">{{ item.costname }}</Option>
              </Select>
            </template>
            <DatePicker type="daterange"
                        :start-date="new Date()"
                        placeholder="请选择时间范围"
                        :value="date"
                        @on-change="onChangeDate"
                        class="m-r-10"
                        style="width:260px"></DatePicker>
          </div>
          <div class="flex">
            <Button type="primary"
                    class="m-l-10"
                    v-if="checkPermi(['/admin/personCost/query'])"
                    @click.stop="onClickSearch">
              <Icon custom="i-icon icon-sousuo"
                    size="16"></Icon>
              查询
            </Button>
            <Button class="m-l-10"
                    v-if="checkPermi(['/admin/personCost/query'])"
                    @click.stop="onClickReset">
              <Icon custom="i-icon icon-shuaxin"
                    size="16"></Icon>
              重置
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="flex">
        <Button type="primary"
                class="m-r-10"
                v-if="checkPermi(['/admin/personCost/add'])"
                @click.stop="onClickAddModal">
          <Icon custom="i-icon icon-tianjia1"
                size="16"></Icon>
          添加
        </Button>
        <Dropdown v-if="checkPermi(['/admin/personCost/export'])">
          <Button class="m-r-10">
            <Icon custom="i-icon icon-shangchuan"
                  size="16"></Icon>
            导出
          </Button>
          <DropdownMenu slot="list">
            <DropdownItem name="part">
              <ExportTemplate :path="getExportPath"
                              :data="getExportData('part')">
                <Button>
                  导出部分
                </Button>
              </ExportTemplate>
            </DropdownItem>
            <DropdownItem name="now">
              <ExportTemplate :path="getExportPath"
                              :data="getExportData('now')">
                <Button>
                  导出本页
                </Button>
              </ExportTemplate>
            </DropdownItem>
            <DropdownItem name="all">
              <ExportTemplate :path="getExportPath"
                              :data="getExportData('all')">
                <Button>
                  导出全部
                </Button>
              </ExportTemplate>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <ExportTemplate :path="getExportTemplatePath"
                        v-if="checkPermi(['/admin/personCost/export'])">
          <Button class="m-r-10">
            <Icon custom="i-icon icon-shangchuan"
                  size="16"></Icon>
            导出模板
          </Button>
        </ExportTemplate>
        <ReaderFile @uploadSuccess="onClickReader"
                    accept=".xls,.xlsx"
                    :action='getReaderFileAction'
                    v-if="checkPermi(['/admin/personCost/import'])">
          <Button class="m-r-10">
            <Icon custom="i-icon icon-xiazai"
                  size="16"></Icon>
            导入
          </Button>
        </ReaderFile>
        <Dropdown @on-click="onClickBatch"
                  v-if="checkPermi(['/admin/personCost/delete'])">
          <Button class="m-r-10">
            <Icon custom="i-icon icon-xiugai1"
                  size="16"></Icon>
            批量操作
          </Button>
          <DropdownMenu slot="list">
            <DropdownItem name="delete"
                          v-if="checkPermi(['/admin/personCost/delete'])">批量删除</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
      <div class="table">
        <Table ref="table"
               :height="tableHeight"
               :columns="getColumns"
               :data="data"
               border
               stripe
               :loading="tableLoading"
               :row-class-name="rowClassName"
               @on-selection-change="onChangeSelect"
               @on-sort-change="onChangeSort">
          <template slot-scope="{ row }"
                    slot="operation">
            <template v-if="currentTabIndex===0">
              <Button type="text"
                      class="tableOperaBtn"
                      v-hasPermi="['/admin/personCost/edit']"
                      @click.stop="onClickEditPersonCostWage(row)">修改</Button>
              <Button type="text"
                      class="tableOperaBtn"
                      v-hasPermi="['/admin/personCost/delete']"
                      @click.stop="onClickDeletePersonCostWage(row)">删除</Button>
            </template>
            <template v-if="currentTabIndex===1">
              <Button type="text"
                      class="tableOperaBtn"
                      v-hasPermi="['/admin/personCost/edit']"
                      @click.stop="onClickEditPersonCostOther(row)">修改</Button>
              <Button type="text"
                      class="tableOperaBtn"
                      v-hasPermi="['/admin/personCost/delete']"
                      @click.stop="onClickDeletePersonCostOther(row)">删除</Button>
            </template>
          </template>
        </Table>
        <Page class="m-t-10 m-l-10"
              :total="totalPage"
              :current="pageNum"
              :page-size="pageSize"
              show-total
              show-sizer
              show-elevator
              @on-change="onPageChange"
              @on-page-size-change="onPageSizeChange" />
      </div>
    </div>

    <!-- 工资组成 -->
    <PersonCostWageModal v-model="personCostWageVisible"
                         :dataId.sync="personCostWageId"
                         @onClickConfirm="getList" @onChange='onChangClearRowStyle'></PersonCostWageModal>

    <!-- 人员杂费 -->
    <PersonCostOtherModal v-model="personCostOtherVisible"
                          :dataId.sync="personCostOtherId"
                          @onClickConfirm="getList" @onChange='onChangClearRowStyle'></PersonCostOtherModal>
  </div>
</template>

<script>
import PersonCostWageModal from '@/components/product/admin/modal/cost/PersonCostWageModal'
import PersonCostOtherModal from '@/components/product/admin/modal/cost/PersonCostOtherModal'
import ExportTemplate from '@/components/common/ExportTemplate'
import ReaderFile from '@/components/common/ReaderFile'
import { checkPermi } from '@/utils/permission'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    PersonCostWageModal,
    PersonCostOtherModal,
    ExportTemplate,
    ReaderFile
  },
  data () {
    return {
      currentTabIndex: 0,
      tabArr: [
        {
          name: '工资组成'
        },
        {
          name: '人员杂费'
        },
      ],
      name: '',
      idNumber: '',
      workNumber: '',
      costType: -1,
      costTypeArr: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '过节费'
        },
        {
          id: 2,
          name: '差旅费'
        },
        {
          id: 3,
          name: '其他'
        },
      ],
      date: [],
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableHeight: 0,
      tableLoading: false,
      columnsWage: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '姓名',
          key: 'empName',
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'empdeptName',
          width: 80,
          tooltip: true,
          align: 'center'
        },
        {
          title: '工种',
          key: 'workTypeName',
          tooltip: true,
          width: 60,
          align: 'center'
        },
        // {
        //   title: '作业大类',
        //   key: 'persontype',
        //   tooltip: true,
        //   align: 'center'
        // },
        {
          title: '工号',
          key: 'emplnum',
          tooltip: true,
          width: 60,
          align: 'center'
        },
        {
          title: '身份证号',
          key: 'idcardnum',
          tooltip: true,
          width: 80,
          align: 'center'
        },
        {
          title: '月份',
          key: 'month',
          tooltip: true,
          width: 60,
          align: 'center'
        },
        {
          title: '发放日期',
          key: 'paydate',
          width: 80,
          tooltip: true,
          align: 'center'
        },
        {
          title: '实发工资(元)',
          key: 'totalsalary',
          tooltip: true,
          width: 100,
          align: 'center'
        },
        {
          title: '考勤天数(天)',
          key: 'attendays',
          tooltip: true,
          width: 100,
          align: 'center'
        },
        {
          title: '基础工资(元)',
          key: 'basicSalary',
          tooltip: true,
          width: 100,
          align: 'center'
        },
        {
          title: '岗位工资(元)',
          key: 'postSalary',
          tooltip: true,
          width: 100,
          align: 'center'
        },
        {
          title: '绩效考核(元)',
          key: 'meritSalary',
          tooltip: true,
          width: 100,
          align: 'center'
        },
        {
          title: '补贴(元)',
          key: 'subsidy',
          tooltip: true,
          width: 80,
          align: 'center'
        },
        {
          title: '社保(元)',
          key: 'socialSecurity',
          tooltip: true,
          width: 80,
          align: 'center'
        },
        {
          title: '公积金(元)',
          key: 'providentFund',
          tooltip: true,
          width: 80,
          align: 'center'
        },
        {
          title: '个税(元)',
          key: 'incomeTax',
          tooltip: true,
          width: 80,
          align: 'center'
        },
        {
          title: '扣款(元)',
          key: 'deduction',
          tooltip: true,
          width: 80,
          align: 'center'
        },
        {
          title: '其他(元)',
          key: 'otherpay',
          tooltip: true,
          width: 80,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 100,
          align: 'center'
        }
      ],
      columnsOther: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '姓名',
          key: 'empName',
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'empdeptName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '工种',
          key: 'workTypeName',
          tooltip: true,
          align: 'center'
        },
        // {
        //   title: '作业大类',
        //   key: 'persontype',
        //   tooltip: true,
        //   align: 'center'
        // },
        {
          title: '工号',
          key: 'emplnum',
          tooltip: true,
          align: 'center'
        },
        {
          title: '身份证号',
          key: 'idcardnum',
          tooltip: true,
          align: 'center'
        },
        {
          title: '费用',
          key: 'paycost',
          tooltip: true,
          align: 'center'
        },
        {
          title: '费用类型',
          key: 'costtypeName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '缴费时间',
          key: 'paydate',
          tooltip: true,
          minWidth: 80,
          align: 'center'
        },
        // {
        //   title: '缴费人',
        //   key: 'managePhone',
        //   tooltip: true,
        //   align: 'center'
        // },
        {
          title: '地址',
          key: 'addr',
          tooltip: true,
          align: 'center'
        },
        {
          title: '附件',
          key: 'fileurl',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.atta) {
              if (row.atta) {
                if (this.getFileType(row.atta) == 1) {
                  return h('el-image', {
                    props: {
                      src: row.atta,
                      'preview-src-list': [row.atta]
                    },
                    style: {
                      width: '22px',
                      height: '22px',
                      cursor: 'pointer'
                    }
                  })
                } else {
                  return h('img', {
                    domProps: {
                      src: require('../../../assets/common/word.png')
                    },
                    style: {
                      width: '22px',
                      height: '22px',
                      cursor: 'pointer'
                    },
                    on: {
                      click: () => {
                        this.onClickDownFile(row)
                      }
                    }
                  })
                }
              }
            }
            return h('span', '')
          }
        },
        {
          title: '备注',
          key: 'remark',
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          minWidth: 80,
          align: 'center'
        }
      ],
      data: [],
      selectArr: [],
      selectDataArr: [],
      selectIndexArr: [],
      selectVisitedArr: [],
      selectVisitedIndexArr: [],
      // 工资组成
      personCostWageVisible: false,
      personCostWageId: '',
      // 人员杂费
      personCostOtherVisible: false,
      personCostOtherId: ''
    };
  },
  watch: {
    currentTabIndex (newVal) {
      this.resetSearchData()
      this.onClickSearch()
      if (newVal == 1) {
        console.log(newVal);
        this.getDoctSmallTypeItem()
      }
    },
    selectDataArr (newVal) {
      this.selectIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          let data = this.data.find((items, index) => {
            return item.id == items.id
          })
          this.selectIndexArr.push(data.id)
        })
      }
    },
    selectVisitedArr (newVal) {
      console.log(newVal);
      this.selectVisitedIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          let data = this.data.find((items, index) => {
            return item.id == items.id
          })
          this.selectVisitedIndexArr = [data.id]
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      'dictBigTypeList': 'getDictBigTypeList'
    }),
    getColumns () {
      let columns = []
      switch (this.currentTabIndex) {
        case 0:
          columns = this.columnsWage
          break;
        case 1:
          columns = this.columnsOther
          break;
        default:
          break;
      }
      return columns
    },
    getExportPath () {
      let path = ''
      switch (this.currentTabIndex) {
        case 0:
          path = this.$http.exportPersonCostWage
          break;
        case 1:
          path = this.$http.exportPersonCostOther
          break;
        default:
          break;
      }
      return path
    },
    getExportTemplatePath () {
      let path = ''
      switch (this.currentTabIndex) {
        case 0:
          path = this.$http.exportPersonCostWageTem
          break;
        case 1:
          path = this.$http.exportPersonCostOtherTem
          break;
        default:
          break;
      }
      return path
    },
    getReaderFileAction () {
      let readerFileAction = []
      switch (this.currentTabIndex) {
        case 0:
          readerFileAction = '/hss/tlempsalary/excelReader'
          break;
        case 1:
          readerFileAction = '/hss/tlempincidental/excelReader'
          break;
        default:
          break;
      }
      return readerFileAction
    }
  },
  mounted () {
    this.init()
  },
  destroyed () {
    window.onresize = null
  },
  methods: {
    checkPermi,
    ...mapActions([
      'updateDictBigTypeList'
    ]),
    init () {
      this.tableHeight = window.innerHeight - 350
      window.onresize = () => {
        this.tableHeight = window.innerHeight - 350
      }
      this.updateDictBigTypeList()
      this.getList()
    },
    getDoctSmallTypeItem () {
      let index = this.dictBigTypeList.findIndex(item => {
        return item.costtype == '人员杂费'
      })
      this.$http.getCostDictionariesItemAllList({ id: this.dictBigTypeList[index].id }).then((res) => {
        if (res.code === 200) {
          this.costTypeArr = res.result
        }
      })
    },
    // 判断文件类型
    getFileType (file) {
      let filePath = file
      let index = filePath.lastIndexOf(".");
      let type = filePath.substr(index + 1);
      let imgArr = ['tif', 'pjp', 'webp', 'xbm', 'jxl', 'svgz', 'jpg', 'jpeg', 'ico', 'tiff', 'gif', 'svg', 'bmp', 'png', 'jfif', 'pjpeg', 'avif']
      if (imgArr.includes(type)) {
        return 1
      } else {
        return 2
      }
    },
    // 点击下载文件
    onClickDownFile (row) {
      console.log(row);
      window.open(row.atta)
    },
    onClickSet () {

    },
    onChangeDate (date) {
      this.date = date
    },
    rowClassName (row, index) {
      if (this.selectVisitedIndexArr.includes(row.id)) {
        return 'table-visited-bgcolor'
      }
      if (this.selectIndexArr.includes(row.id)) {
        return 'table-bgcolor'
      }
    },
    onClickBatch (name) {
      switch (name) {
        case 'delete':
          switch (this.currentTabIndex) {
            case 0:
              this.onDeletePersonCostWage(this.selectArr)
              break;
            case 1:
              this.onDeletePersonCostOther(this.selectArr)
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    },
    onChangeSelect (selection) {
      this.selectArr = selection.map(item => item.id)
      this.selectDataArr = selection
    },
    onChangeSort (column, key, order) {

    },
    rowClassName (row, index) {
      if (this.selectVisitedIndexArr.includes(row.id)) {
        return 'table-visited-bgcolor'
      }
      if (this.selectIndexArr.includes(row.id)) {
        return 'table-bgcolor'
      }
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    onClickAddModal () {
      switch (this.currentTabIndex) {
        case 0:
          this.personCostWageVisible = true
          break;
        case 1:
          this.personCostOtherVisible = true
          break;
        default:
          break;
      }
    },
    getList () {
      this.selectIndexArr = []
      switch (this.currentTabIndex) {
        case 0:
          this.getPersonCostWageList()
          break;
        case 1:
          this.getPersonCostOtherList()
          break;
        default:
          break;
      }
    },
    // 工资组成
    getPersonCostWageList () {
      let params = {
        name: this.name,
        idcard: this.idNumber,
        cardnum: this.workNumber,
        page: this.pageNum,
        size: this.pageSize
      }
      if (this.date[0]) {
        params.startdate = this.date[0]
        params.enddate = this.date[1]
      }
      this.tableLoading = true
      this.$http.getPersonCostWageList(params).then((res) => {
        this.data = res.result.resultlist
        this.totalPage = res.result.total
        this.tableLoading = false
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 工资组成修改
    onClickEditPersonCostWage (row) {
      this.selectVisitedArr.push(row)
      this.personCostWageId = row.id
      this.personCostWageVisible = true
    },
    // 工资组成删除
    onClickDeletePersonCostWage (row) {
      this.selectVisitedArr.push(row)
      this.onDeletePersonCostWage([row.id])
    },
    // 工资组成删除
    onDeletePersonCostWage (arr) {
      this.$Modal.confirm({
        title: '删除',
        content: '确认删除?',
        onOk: () => {
          let params = {
            ids: arr
          }
          this.$http.deletePersonCostWage(params).then(res => {
            this.$Message.info(res.message)
            if (res.code === 200) {
              this.onChangClearRowStyle()
              this.getList()
            }
          })
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    // 杂费
    getPersonCostOtherList () {
      let params = {
        fullName: this.name,
        idCardNo: this.idNumber,
        jobNumber: this.workNumber,
        costType: this.costType,
        page: this.pageNum,
        size: this.pageSize
      }
      if (this.date[0]) {
        params.startdate = this.date[0]
        params.enddate = this.date[1]
      }
      this.tableLoading = true
      this.$http.getPersonCostOtherList(params).then((res) => {
        this.tableLoading = false
        this.data = res.result.rows
        this.totalPage = res.result.total
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 杂费修改
    onClickEditPersonCostOther (row) {
      this.selectVisitedArr.push(row)
      this.personCostOtherId = row.id
      this.personCostOtherVisible = true
    },
    // 杂费删除
    onClickDeletePersonCostOther (row) {
      this.selectVisitedArr.push(row)
      this.onDeletePersonCostOther([row.id])
    },
    // 杂费删除
    onDeletePersonCostOther (arr) {
      this.$Modal.confirm({
        title: '删除',
        content: '确认删除?',
        onOk: () => {
          let params = {
            ids: arr
          }
          this.$http.deletePersonCostOther(params).then(res => {
            this.$Message.info(res.message)
            if (res.code === 200) {
              this.onChangClearRowStyle()
              this.getList()
            }
          })
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    // 查询
    onClickSearch () {
      this.pageNum = 1
      this.getList()
    },
    // 点击重置
    onClickReset () {
      this.resetSearchData()
      this.onClickSearch()
    },
    // 重置搜索条件
    resetSearchData () {
      this.name = ''
      this.idNumber = ''
      this.workNumber = ''
      this.costType = ''
      this.date = []
    },
    onChangClearRowStyle () {
      this.selectVisitedArr = []
      
    },
    // 导出文件数据
    getExportData (value) {
      let data = {}
      switch (this.currentTabIndex) {
        case 0:
          data = {
            fullName: this.name,
            idCardNo: this.idNumber,
            jobNumber: this.workNumber,
          }
          if (this.date[0]) {
            data.startTime = this.date[0]
            data.endTime = this.date[1]
          }
          if (value == 'now') {
            data.ids = this.data.map(item => {
              return item.id
            })
          } else if (value == 'part') {
            data.ids = this.selectArr
          } else {
            // data.ids = []
          }
          break;
        case 1:
          data = {
            fullName: this.name,
            idCardNo: this.idNumber,
            jobNumber: this.workNumber,
            costType: this.costType
          }
          if (this.date[0]) {
            data.startTime = this.date[0]
            data.endTime = this.date[1]
          }
          if (value == 'now') {
            data.ids = this.data.map(item => {
              return item.id
            })
          } else if (value == 'part') {
            data.ids = this.selectArr
          } else {
            // data.ids = []
          }
          break;
        default:
          break;
      }
      return data
    },
    // 导入回调
    onClickReader (arr) {
      if (arr.length > 0) {
        this.$Message.info('导入成功')
        this.getList()
      }
    },
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";
::v-deep {
  .ivu-table .table-bgcolor td {
    background: #ebf7ff !important;
    border-right: 1px solid #ebf7ff;
  }
  .ivu-table .table-visited-bgcolor td {
    background: rgb(235, 247, 255) !important;
    border-right: 1px solid rgb(235, 247, 255);
  }
}
.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f0f2f5;
  .topbar {
    background-color: #fff;
    .topbar-search {
      padding: 16px;
    }
  }
  .content {
    padding: 16px;
    background-color: #fff;
    margin-top: 16px;
    .tree {
      margin-top: 16px;
      width: 230px;
      margin-right: 16px;
      overflow-y: auto;
      flex-shrink: 0;
      border: 1px solid #e8eaec;
    }
    .table {
      margin-top: 16px;
      flex: 1;
    }
  }
}
</style>